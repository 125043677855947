$font-weight-normal: 400;
$font-weight-bold: 700;

$black-transparent: transparent;
$black-light: #111111;
$primary-black: #000000;
$primary-red: #F81D3D;
$primary-green: #5fc15f;
$primary-white: #FFFFFF;

$subscribe-background-color: #000000f3;

$bread-crumps-color: #8A9399;
$bread-crumps-color-hover: #111314;

$full-width: 100vw;
$full-height: 100vh;

$dark-ligthning: #333333;

$header-date-color: $bread-crumps-color;
$header-job-title: $header-date-color;

$table-primary-color: #F5F5F5;
$table-second-color: #FFFFFF;