.related-article {
  display: flex;
  background-color: #f6f4f4;
  gap: 32px;
  text-decoration: none; /* Remove default link underline */
  color: inherit; /* Keep text color */
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.related-article:hover {
  background-color: #eeecec; /* Slightly darker on hover */
}

.related-article-content {
  display: flex;
  width: 100%;
  gap: 24px;
}

.article-image {
  width: 200px;
  height: 132px;
  flex-shrink: 0;
  background-color: #f6f4f4;
  position: relative;
  overflow: hidden;
}

/* Add shimmer animation when no background image */
.article-image:not([style*="background-image"])::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(235, 14, 14, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.article-text-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  justify-content: center;
}

.article-label {
  margin: 0;
  color: rgba(22, 20, 19, 0.56);

  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.article-title {
  margin: 0;
  font-size: 20px!important;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}

.related-article-hide-content {
  display: none;
}
