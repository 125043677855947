@import "../default";

.work-page-content-container {
  max-width: 800px;
  margin: auto;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 100px;
  #content {
    & > * {
      color: #111314 !important;
      font-weight: $font-weight-normal;
      font-size: 1.125rem;
      line-height: 30px;
      letter-spacing: 0px;
    }

    & > p,
    & > ol,
    & > ul,
    & > li,
    & > figure.table,
    & > figure.image figcaption {
      margin: auto;
      max-width: 608px;
      margin-bottom: 1.3em;
    }

    & > figure.image figcaption {
      font-style: italic;
    }

    a {
      color: $primary-red;
    }

    & > blockquote {
      margin: auto;
      max-width: 608px;
      margin-bottom: 1.5rem;
    }

    li {
      padding-bottom: 0.5rem;
    }

    figure.image {
      margin-top: 3rem;
      margin-bottom: 3rem;

      img {
        height: auto;
        width: 100%;
      }
    }

    h3 {
      margin: auto;
      max-width: 608px;
      font-weight: 300;
      margin-bottom: 0.5em;
    }

    h2 {
      margin: 4em auto 1.75em;
      max-width: 608px;
      position: relative;
      &:before {
        width: 69px;
        height: 1px;
        display: block;
        content: "";
        position: absolute;
        top: 0.75em;
        left: -95px;
        background-color: $primary-red;
      }
      strong {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 20px;
        color: #000000;
      }
    }

    h3 {
      font-size: 1.5em;
      line-height: 1.3;
      margin-top: 2em;
    }

    blockquote {
      position: relative;

      &:before {
        display: block;
        content: url(/svg/quotes.svg);
        position: absolute;
        top: -36px;
        left: 0;
      }

      p {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 32px;
        margin-bottom: 1em;
        margin-top: 4rem;
        &:not(:first-child) {
          font-weight: normal;
          font-size: 1rem;
          line-height: 20px;
          margin-bottom: 0.375em;
          margin-top: 0;
        }
      }
    }

    figure {
      overflow: auto;

      table {
        max-width: 100%;
        margin: auto;
        border-collapse: collapse;

        thead {
          th {
            text-align: left;
            background-color: $table-primary-color;
            color: $primary-black;
            border: none;
          }
        }

        tbody {
          tr {
            &:nth-child(odd) {
              background-color: $table-second-color;
            }

            &:nth-child(even) {
              background-color: $table-primary-color;
            }

            td {
              border: none;
            }
          }
        }

        th,
        td {
          padding: 0.375rem;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .work-page-content-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 72px;
    #content {
      h2 {
        margin-left: 61px;
        &:before {
          width: 48px;
          left: -61px;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .work-page-content-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 72px;
    #content {
      blockquote {
        p {
          font-size: 	1.0625rem;
          line-height: 24px;        
          &:not(:first-child) {
            font-size: 	0.6875rem;            
          }
        }
      }
    }
  }
}
