.banner {
  height: auto;
  background-color: #F6F4F4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;
}

.banner-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.banner-title {
  margin: 0;
  color: #161413;
  font-size: 20px!important;
  font-style: normal;
  font-weight: 600!important;
  line-height: 28px!important;
  letter-spacing: -0.4px;
  margin: 0!important;
}

.banner-description {
  font-size: 14px!important;
  font-style: normal;
  font-weight: 400!important;
  line-height: 20px!important;
  margin: 0;
  color: #423b38;
}

.banner-button {
  background-color: #f81d3d;
  padding: 8px 16px;
  color: #fff !important;
  border-radius: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
}

.banner-button:focus {
  background-color: #000;
  color: #000!important;
}

.banner-hide-content {
  display: none;
}