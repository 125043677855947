$md-color-black: #000000;
$md-color-red: #f81d3d;

.work-page-technology-container {
  max-width: 1280px;
  margin: 0 auto 140px;
  height: auto;
  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;

  * {
    font-weight: 400;
  }

  .title-container {
    margin: 0 12%;
    display: flex;
    flex-direction: row;

    h2 {
      margin-left: 25px;
      font-size: 16px;
      line-height: 20px;
      color: $md-color-black;
    }
  }

  .stack-container {
    margin: 20px 179px 0 255px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .stack-column {
      max-width: 368px;
      width: 100%;

      .stack-item {
        margin-top: 40px;
        h4 {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: $md-color-black;
        }

        div {
          margin-top: 16px;
          font-weight: 400;
          font-size: 18px;
          line-height: 32px;
          color: $md-color-black;

          & > span {
            & > .tech-name {
              font-weight: normal;
              font-size: 18px;
              line-height: 32px;
              color: $md-color-black;
            }

            & > span.red {
              color: $md-color-red;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  .work-page-technology-container {
    margin: 0 auto 72px;

    .stack-container {
      width: 90%;
      margin: 0 auto;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .stack-column {
        .stack-item {
          margin-top: 30px;

          h4 {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: $md-color-black;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .work-page-technology-container {
    margin-bottom: 110px;

    .stack-container {
      .stack-column {
        .stack-item {
          margin-top: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .work-page-technology-container {
    margin-bottom: 80px;
    .title-container {
      margin: 0 5%;
      img {
        width: 48px;
      }
    }
    .stack-container {
      align-items: center;
    }
  }
}
