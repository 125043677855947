body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headroom header {
  background-color: #e8551f !important;
}

.layout {
  background-color: #fff !important;
}

body
  > div[role="dialog"]
  > div:last-child[role="document"]
  #alert-dialog-title {
  display: none;
}

body
  > div[role="dialog"]
  > div:last-child[role="document"]
  > div:nth-child(2)
  > p {
  padding: 20px 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.3125rem;
  font-weight: 500;
}
