.get-in-touch-banner {
  background-color: #F6F4F4;
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
}

.get-in-touch-banner-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.get-in-touch-banner-title {
  color: #161413;
  font-size: 20px!important;
  font-style: normal;
  font-weight: 600!important;
  line-height: 28px!important;
  letter-spacing: -0.4px;
  margin: 0!important;

}

.get-in-touch-banner-description {
  color: #423b38;
  font-size: 14px!important;
  font-style: normal;
  font-weight: 400!important;
  line-height: 20px!important;
  margin: 0;
}

.get-in-touch-banner-link {
  border-radius: 12px;
  background: #f81d3d;
  color: #fff !important;
  display: flex;
  flex-shrink: 0;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 16px!important;
  font-style: normal;
  font-weight: 600!important;
  line-height: 24px!important; /* 150% */
  letter-spacing: -0.16px!important;
}

.get-in-touch-banner-link {
  color: #fff !important;
  text-decoration: none !important;
  text-transform: none !important;
}

.get-in-touch-banner-link:hover {
  background-color: #ff3a57;
}

.get-in-touch-banner-link:active {
  text-decoration: none !important;
  color: #fff !important;

}

.get-in-touch-banner-link:focus {
  color: #000 !important;
}

.get-in-touch-banner-link:visited {
  text-decoration: none !important;
  color: #fff !important;
}

.get-in-touch-banner-hide-content {
  display: none;
}