@import "../default";

.article-author-container {
  margin: 4rem auto 0;
  width: 608px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .figure {
    width: 155px;
    height: 155px;
    margin-right: 2em;
  }

  .author-avatar {
    display: block;
    width: 155px;
    height: 155px;
    object-fit: cover;
    object-position: center center;
    border-radius: 50%;
    flex: 1 0 auto;
  }

  .author-info {
    font-weight: $font-weight-normal;

    .full-name {
      font-size: 2rem;
      line-height: 2.625rem;
      color: $dark-ligthning;
    }

    .job-titile {
      font-size: 1.125rem;
      line-height: 1.5rem;

      color: $bread-crumps-color;
    }

    .bio {
      margin-top: 1rem;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.875rem;
      color: $dark-ligthning;
    }
  }
}

@media screen and (max-width: 650px) {
  .article-author-container {
    width: 90%;
    flex-direction: column;
  }

  .author-info {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .article-author-container {
    margin-top: 2rem;
  }
}
