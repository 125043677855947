$md-color-black: #000000;
$md-color-red: #f81d3d;

.work-page-preview-container {
  max-width: 1280px;
  margin: 100px auto 140px;
  height: auto;
  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;

  * {
    font-weight: 400;
  }

  .title-container {
    margin: 0 12%;
    display: flex;
    flex-direction: row;

    h1 {
      margin-left: 25px;
      font-size: 16px;
      line-height: 20px;
      color: $md-color-black;
    }
  }
  .description {
    margin-top: 41px;
    margin-left: 15%;
    display: flex;
    justify-content: space-between;
    .main-info {
      h2 {
        margin-top: 37px;
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 36px;
        line-height: 50px;
        word-break: break-word;
        max-width: 360px;
      }
      h3 {
        margin-top: 24px;
        margin-bottom: 9px;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #f81d3d;
        text-transform: uppercase;
      }
    }
    .case-picture {
      max-width: 672px;
      img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .work-page-preview-container {
    .description {
      display: block;
      margin-left: 12%;
      margin-right: 12%;
    }
    .case-picture {
      margin-top: 72px;
    }
  }
}

@media screen and (max-width: 480px) {
  .work-page-preview-container {
    margin: 124px auto 72px;
    .title-container {
      margin: 0 5%;
      img {
        width: 48px;
      }
    }
    .description {
      margin: 16px 0 0;
      .main-info {
        margin: 0 5%;
      }
    }
  }
}
